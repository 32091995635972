.profiles {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
}
.profiles .profile-header {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.profiles .profile-header .t-2 {
  margin: 0;
  font-size: var(--size-102);
  color: var(--grey-color-2);
}
.profiles .profile-body {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  padding: var(--size-05);
}
.profiles .profile-body .pb-left,
.profiles .profile-body .pb-right {
  height: 100%;
  display: block;
}
.profiles .profile-body .pb-left {
  width: 40%;
  border-right: 1px solid var(--grey-color-3);
}
.profiles .profile-body .pb-left .column {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--size-05);
}
.profiles .profile-body .pb-left .column .t-2 {
  font-weight: 600;
}
.profiles .profile-body .pb-right {
  width: 60%;
  display: block;
}
.profiles .profile-body .pb-right .profile-image {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profiles .profile-body .pb-right .profile-image img {
  height: 150px;
  border-radius: 100%;
  margin-bottom: var(--size-03);
}
.profiles .profile-body .pb-right .profile-image .button {
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: var(--size-07);
}
.profiles .profile-body .pb-right form {
  width: 80%;
  margin: auto;
  padding: var(--size-2) 0;
}
.profiles .profile-body .pb-right form .t-2 {
  color: var(--grey-color-1);
  font-size: var(--size-1);
}
.profiles .profile-body .pb-right form .button {
  background-color: var(--color-1);
}

.orders {
  position: relative;
}
.orders .orders-inner,
.orders .orders-outer {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.orders .orders-inner {
  display: block;
  z-index: 0;
  overflow-y: auto;
}
.orders .orders-outer {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.orders .orders-inner .order-body {
  width: 100%;
  height: auto;
  max-height: 700px;
  margin-bottom: var(--size-1);
}
.orders .orders-inner .order-body .container {
  width: 80%;
  height: 100%;
  margin: auto;
}
.orders .orders-inner .order-body .container .market {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--size-08) 0;
}
.orders .orders-inner .order-body .container .market .title {
  margin: 0;
}
.orders .orders-inner .order-body .container .market .button {
  background-color: var(--black-color);
  margin-right: var(--size-1);
}
.orders .orders-inner .order-body .container .market .button:hover {
  background-color: var(--grey-color-2);
  color: var(--black-color);
}
.orders .orders-inner .order-body .container .filter {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
}
.orders .orders-inner .order-body .container .filter .button {
  width: 150px;
  height: 40px;
  background-color: var(--grey-color-2);
  color: var(--black-color);
  font-size: var(--size-09);
}
.orders .orders-inner .order-body .container .filter .item {
  border-radius: var(--size-03);
  text-align: center;
  padding: var(--size-05);
  margin: 0 var(--size-05);
  cursor: pointer;
}
.orders .orders-inner .order-body .container .filter .item:hover {
  background-color: var(--grey-color-2);
  transition: all 0.5s ease-in-out;
}
.orders .orders-inner .order-body .container .filter .item img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid var(--color-1);
  padding: var(--size-02);
}
.orders .orders-inner .order-body .container .filter .item .t-2 {
  font-size: var(--size-07);
  font-weight: 600;
}
.orders .orders-inner .order-body .container .filter .button.selected,
.orders .orders-inner .order-body .container .filter .item.selected {
  background-color: var(--black-color);
  color: var(--white-color);
}
.orders .orders-inner .order-body .container .filter .item.selected .t-2 {
  color: var(--white-color);
}
.orders .orders-inner .order-body .container .content {
  width: 100%;
  height: auto;
  max-height: calc(100% - 130px);
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.orders .orders-inner .order-body .container .content .item {
  width: 188px;
  padding: var(--size-02);
  margin: var(--size-02);
}
.orders .orders-inner .order-body .container .content .item img {
  /* width: 100%; */
  height: 120px;
  margin-bottom: var(--size-02);
}
.orders .orders-inner .order-body .container .content .item .t-2 {
  color: var(--color-1);
  font-size: var(--size-08);
  font-weight: 700;
}
.orders .orders-inner .order-body .container .content .item .t-3 {
  font-size: var(--size-1);
  font-weight: bold;
  font-family: "amiri", sans-serif;
}
.orders .orders-inner .order-body .container .content .item .starts {
  display: flex;
  margin: var(--size-05) 0;
}
.orders .orders-inner .order-body .container .content .item .starts .icon {
  font-size: var(--size-102);
}
.orders .orders-inner .order-body .container .content .item .button {
  width: 100%;
  background-color: var(--color-1);
  color: var(--white-color);
  font-size: var(--size-07);
}

.orders .orders-outer .orders-outer-wrapper {
  width: 40%;
  height: 100%;
  background-color: var(--white-color);
  margin-left: auto;
  padding: var(--size-1);
}
.orders .orders-outer .orders-outer-wrapper .oow-head {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid var(--grey-color-2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.orders .orders-outer .orders-outer-wrapper .oow-head .title {
  margin: 0;
}
.orders .orders-outer .orders-outer-wrapper .oow-head .t-2 {
  font-size: var(--size-102);
}
.orders .orders-outer .orders-outer-wrapper .oow-head .t-3 {
  color: var(--grey-color-1);
  font-size: var(--size-1);
}
.orders .orders-outer .orders-outer-wrapper .oow-head .close {
  cursor: pointer;
  font-size: var(--size-105);
}
.orders .orders-outer .orders-outer-wrapper .oow-body {
  width: 100%;
  height: calc(100% - 40px);
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-item {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: var(--size-1) 0;
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-item .oow-item-left {
  display: flex;
  align-items: center;
}
.orders
  .orders-outer
  .orders-outer-wrapper
  .oow-body
  .oow-item
  .oow-item-left
  .button {
  background-color: var(--black-color);
  font-size: var(--size-09);
}
.orders
  .orders-outer
  .orders-outer-wrapper
  .oow-body
  .oow-item
  .oow-item-left
  .button
  .icon {
  font-size: var(--size-102);
}
.orders
  .orders-outer
  .orders-outer-wrapper
  .oow-body
  .oow-item
  .oow-item-left
  img {
  height: 100px;
  margin: 0 var(--size-05);
}
.orders
  .orders-outer
  .orders-outer-wrapper
  .oow-body
  .oow-item
  .oow-item-right {
  display: flex;
  flex-direction: column;
}
.oow-item .oow-item-right .t-2 {
  font-family: "amiri", sans-serif;
  font-size: var(--size-102);
  font-weight: 600;
}
.oow-item .oow-item-right .t-3 {
  font-size: var(--size-1);
}
.oow-item .oow-item-right span {
  font-size: var(--size-09);
  margin-bottom: var(--size-03);
}
.oow-item .oow-item-right .button {
  background-color: var(--color-1);
  font-size: var(--size-07);
}

.orders .orders-outer .orders-outer-wrapper .oow-body .oow-basket {
  width: 100%;
  height: auto;
  max-height: 400px;
  margin-bottom: var(--size-1);
}
.orders .orders-outer .orders-outer-wrapper .oow-body .row{
  display: flex;
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-validate {
  width: 100%;
  display: block;
  text-align: right;
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-validate .t-1 {
  font-size: var(--size-1);
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-validate .t-1 span {
  font-weight: 700;
  font-size: var(--size-105);
}
.orders .orders-outer .orders-outer-wrapper .oow-body .oow-validate .button {
  background-color: var(--green-color);
}

@media only screen and (max-width: 768px) {
}

.user {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user .left,
.user .right {
  height: 100%;
}
.user .left {
  width: 15%;
  position: relative;
}
/* Left */
.user .left .header {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: var(--size-1);
}
.user .left .header .icon{
  font-size: var(--size-2);
}
.user .left .header img {
  width: 80%;
  /* height: 100%; */
}
.user .left .body {
  width: 100%;
  height: calc(100% - 160px);
  padding: var(--size-07);
}
.user .left .body .navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--size-08);
}
.user .left .body .navigation .active-option {
  background-color: rgba(0, 0, 0, 0.15);
  color: var(--color-1);
}
.user .left .body .navigation .inactive-option {
  color: var(--grey-color-1);
}
.user .left .body .navigation .link-option {
  text-decoration: none;
  border-radius: 0.4em;
  transition: all 0.3s ease-in-out;
  padding: var(--size-07) var(--size-04);
}
.user .left .body .navigation .link-option .option-icon {
  margin-right: 1em;
}
.user .left .body .navigation .link-option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
/* Right */
.user .right {
  width: 85%;
}
.user .right .header {
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--size-04);
}
.user .right .header .drawer{
  display: none;
}
.user .right .header .options {
  position: relative;
  display: flex;
  align-items: center;
}
.user .right .header .options .option {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-color: var(--grey-color-3);
  margin: 0px 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}
.user .right .header .options .option span {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--red-color);
  position: absolute;
  right: 8px;
  top: 8px;
}
.user .right .header .options .option:hover {
  background-color: var(--black-color);
}
.user .right .header .options .option:hover .icon-element {
  color: var(--white-color);
}
.user .right .header .options .profile {
  position: relative;
  display: flex;
  align-items: center;
}
.user .right .header .options .profile img {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 1px solid var(--grey-color-2);
  padding: var(--size-02);
}
.user .right .header .options .profile .t-2 {
  font-size: var(--size-07);
  margin: 0 0.5rem;
}
.user .right .header .options .logout {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 0.5rem;
}
/* Right body */
.user .right .body {
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  .user .left,
  .user .right {
    height: 100%;
    position: absolute;
    background-color: var(--white-color);
  }
  .user .left {
    width: 50%;
    z-index: 1;
    left: 0;
    display: none;
  }
  .user .left.is-open{
    display: block !important;
  }
  /* Left */
  .user .left .header {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: var(--size-1);
  }
  .user .left .header img {
    width: 80%;
    /* height: 100%; */
  }
  .user .left .body {
    width: 100%;
    height: calc(100% - 160px);
    padding: var(--size-07);
  }
  .user .left .body .navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: var(--size-08);
  }
  .user .left .body .navigation .active-option {
    background-color: rgba(0, 0, 0, 0.15);
    color: var(--color-1);
  }
  .user .left .body .navigation .inactive-option {
    color: var(--grey-color-1);
  }
  .user .left .body .navigation .link-option {
    text-decoration: none;
    border-radius: 0.4em;
    transition: all 0.3s ease-in-out;
    padding: var(--size-07) var(--size-04);
  }
  .user .left .body .navigation .link-option .option-icon {
    margin-right: 1em;
  }
  .user .left .body .navigation .link-option:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  /* Right */
  .user .right {
    width: 100%;
    z-index: 0;
  }
  .user .right .header {
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--size-04);
  }
  .user .right .header .drawer{
    display: block;
    font-size: var(--size-2);
  }
  .user .right .header .options {
    position: relative;
    display: flex;
    align-items: center;
  }
  .user .right .header .options .option {
    position: relative;
    width: 35px;
    height: 35px;
  }
  .user .right .header .options .profile img {
    width: 35px;
    height: 35px;
  }
  .user .right .header .options .profile .t-2 {
    font-size: var(--size-04);
    margin: 0 var(--size-02);
  }
  .user .right .header .options .logout {
    font-size: var(--size-06);
    margin: 0 var(--size-02);
  }
  /* Right body */
  .user .right .body {
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}
/* Tablet devices (portrait and landscape) */
@media (min-width: 768px) and (max-width: 1024px) {
}
/* Laptops and larger devices */
@media (min-width: 1025px) and (max-width: 1440px) {
  /* Styles for medium screens (laptops and desktops) */
}
/* Large desktop screens */
@media (min-width: 1441px) {
  /* Styles for large desktop screens */
}
/* High-resolution displays (e.g., Retina displays) */
@media only screen and (min-resolution: 192dpi), (min-resolution: 2dppx) {
  /* Styles for high-res screens */
}
/* Portrait Mode */
@media (orientation: portrait) {
  /* Styles for portrait orientation */
}
/* Landscape Mode */
@media (orientation: landscape) {
  /* Styles for landscape orientation */
}

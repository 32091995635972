.communion-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
.communion-wrapper .communion-body {
  width: 100%;
}
.communion-wrapper .communion-body .communion-body-content {
  width: 80%;
  margin: auto;
  padding: var(--size-5) 0;
}
.communion-wrapper .communion-body .communion-body-content .t-1 {
  font-size: var(--size-3);
}
.communion-wrapper .communion-body .communion-body-content hr {
  width: 10%;
  height: 5px;
  background-color: var(--color-2);
  border: none;
  margin: var(--size-3) 0;
}
.communion-wrapper .communion-body .communion-body-content .communion-meal{
    text-align: center;
    margin: var(--size-2) 0;
}
.communion-wrapper .communion-body .communion-body-content .communion-meal img{
    height: 300px;
    border-left: 1px solid var(--color-1);
    border-bottom: 1px solid var(--color-1);
}
.communion-wrapper .communion-body .communion-body-content .t-3 {
  font-size: var(--size-105);
  font-weight: 500;
  text-align: justify;
  margin-bottom: var(--size-1);
}
.communion-wrapper .communion-body .communion-body-content .option {
  display: block;
}
.communion-wrapper .communion-body .communion-body-content .option .t-2 {
  font-size: var(--size-105);
  font-weight: 600;
  display: flex;
  align-items: center;
}
.communion-wrapper .communion-body .communion-body-content .option .t-2 .icon {
  margin-right: var(--size-05);
  color: var(--color-2);
}
.communion-wrapper .communion-body .communion-body-content .option .t-2 span {
  color: var(--color-1);
  font-weight: 600;
}
.communion-wrapper .communion-body .communion-body-content .option .row {
  display: block;
  padding-left: var(--size-2);
}
.communion-wrapper .communion-body .communion-body-content .option .row .t-3 {
  font-size: var(--size-102);
  font-weight: 600;
}
.communion-wrapper .communion-body .communion-body-content .option .row .t-4 {
  font-size: var(--size-09);
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .communion-wrapper .communion-body .communion-body-content {
    width: 90%;
    padding: var(--size-2) 0;
  }
  .communion-wrapper .communion-body .communion-body-content .t-1 {
    font-size: var(--size-2);
  }
  .communion-wrapper .communion-body .communion-body-content hr {
    margin: var(--size-105) 0;
  }
  .communion-wrapper .communion-body .communion-body-content .t-3 {
    color: var(--grey-color-1);
    font-size: var(--size-1);
  }
  .communion-wrapper .communion-body .communion-body-content .option .t-2 {
    font-size: var(--size-102);
  }
  .communion-wrapper .communion-body .communion-body-content .option .row .t-3 {
    font-size: var(--size-1);
  }
  .communion-wrapper .communion-body .communion-body-content .option .row .t-4 {
    text-align: justify;
  }
}

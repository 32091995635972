.configuration{
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
}
.configuration .conf-head{
    width: 100%;
    height: 90px;
}
.configuration .conf-head .t-2{
    font-size: var(--size-102);
    font-weight: 700;
}
.configuration .conf-head .t-3{
    font-size: var(--size-07);
    font-weight: 600;
}
.configuration .conf-head .navs{
    display: flex;
}
.configuration .conf-head .navs .link-option{
    text-decoration: none;
    padding: var(--size-05) var(--size-1);
}
.configuration .conf-head .navs .active-option{
    background-color: var(--color-1);
    color: var(--white-color);
    font-weight: 600;
}
.configuration .conf-head .navs .inactive-option{
    color: var(--grey-color-1);
    font-weight: 600;
}
.configuration .conf-body{
    width: 100%;
    height: calc(100% - 90px);
}

@import "./_colors.css";
@import "./_sizes.css";
@import "./_fonts.css";
@import "./_grids.css";
@import "./_utils.css";
@import "./_inputs.css";
@import "./_buttons.css";
@import "./_columns.css";
@import "./_table.css";

*,
*::after,
*::before {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  outline: none;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey-color-1);
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
  font-family: "raleway-regular", sans-serif;
  font-size: var(--size-1);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

:root {
  --color-1: #8b5220;
  --color-2: #b07645;
  --color-3: #f2c480;
  --color-4: #f2d3a1;
  --color-5: #e5ccac;
  --color-6: #0b212f;
  --green-color: #00a000;
  --white-color: #ffffff;
  --black-color: #000;
  --red-color: #ff0000;
  --grey-color-1: #80868b;
  --grey-color-2: #dadce0;
  --grey-color-3: #f3f5f7;
}

.categories {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
}
.categories .inner,
.categories .outer {
  width: 100%;
  height: 100%;
  position: absolute;
}
/* Inner */
.categories .inner {
  display: block;
}
.categories .inner .actions {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-02) 0;
}
.categories .inner .actions .left {
  display: flex;
}
.categories .inner .actions .left .inputs-form {
  position: relative;
}
.categories .inner .actions .left .inputs-form input {
  position: relative;
  width: 300px;
  height: 100%;
  padding: var(--size-02) var(--size-02) var(--size-02) var(--size-107);
  border-radius: var(--size-05);
  border: 1px solid var(--grey-color-2);
}
.categories .inner .actions .left .inputs-form .icon {
  position: absolute;
  top: var(--size-05);
  left: var(--size-03);
  z-index: 1;
}
.categories .inner .actions .left .button {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
.categories .inner .actions .left .button .icon {
  margin-right: var(--size-07);
}
.categories .inner .actions .left select {
  border-radius: var(--size-05);
  border-color: var(--grey-color-2);
  font-size: var(--size-08);
  padding: var(--size-02);
}
.categories .inner .actions .right {
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.categories .inner .actions .right .button {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
.categories .inner .actions .right .button:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.categories .inner .actions .right .button .icon {
  margin-right: var(--size-05);
}
.categories .inner .actions .right .button.btn-new {
  background-color: var(--color-1);
}
.categories .inner .actions .right .button.btn-export {
  background-color: var(--black-color);
}
.categories .inner .actions .right .button.btn-validate {
  background-color: var(--green-color);
}
.categories .inner .content {
  width: 100%;
  height: calc(100% - 80px);
}
.categories .inner .content .table {
  overflow-y: auto;
}
.categories .inner .content .table thead th {
  background-color: var(--grey-color-3);
  position: sticky;
  top: 0;
  z-index: 1;
}
.categories .inner .content .table .row {
  display: flex;
  align-items: center;
}
.categories .inner .content .table .row img {
  width: 35px;
  height: 35px;
  border: 1px solid var(--grey-color-2);
  border-radius: 100%;
  padding: var(--size-02);
  margin-right: var(--size-03);
}
.categories .inner .content .table .row span {
  font-size: var(--size-1);
  font-weight: 700;
}
.categories .inner .content .table .btn-action {
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
/* Outer */
.categories .outer {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: var(--size-1);
}
.categories .outer .wrapper {
  width: 50%;
  background-color: var(--white-color);
  border-radius: var(--size-05);
  margin: auto;
  padding: var(--size-05);
}
.categories .outer .wrapper .d-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.categories .outer .wrapper .d-head .t-2 {
  margin: 0;
  font-size: var(--size-1);
  font-weight: 600;
}
.categories .outer .wrapper .d-head span {
  cursor: pointer;
  font-size: var(--size-2);
}
.categories .outer .wrapper hr {
  border: none;
  width: 100%;
  height: 1px;
  background-color: var(--grey-color-2);
  margin: var(--size-1) 0;
}
.categories .outer .wrapper form {
  width: 100%;
}
.categories .outer .wrapper form .img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--size-05) 0;
}
.categories .outer .wrapper form .img-wrapper img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid var(--grey-color-2);
  padding: var(--size-02);
  margin: 0 var(--size-08);
}
.categories .outer .wrapper form .img-wrapper .button {
  width: 20%;
  background-color: var(--black-color);
  font-size: var(--size-07);
}
.categories .outer .wrapper form .row {
  display: flex;
  justify-content: space-between;
}
.categories .outer .wrapper form .row .input-div {
  width: 49%;
}
.categories .outer .wrapper form .button {
  width: 100%;
  background-color: var(--color-1);
}

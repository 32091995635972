@font-face {
  font-family: "amiri";
  src: url("../../fonts/Amiri/Amiri-Regular.ttf");
}

@font-face {
  font-family: "raleway-light";
  src: url("../../fonts/Raleway/static/Raleway-Light.ttf");
}

@font-face {
  font-family: "raleway-medium";
  src: url("../../fonts/Raleway/static/Raleway-Medium.ttf");
}

@font-face {
  font-family: "raleway-regular";
  src: url("../../fonts/Raleway/static/Raleway-Regular.ttf");
}

@font-face {
  font-family: "shalimar-regular";
  src: url("../../fonts/Shalimar/Shalimar-Regular.ttf");
}

.table {
  width: 100%;
  height: auto;
  max-height: 95%;
  border: 1px solid var(--grey-color-3);
  border-radius: var(--size-03);
}
.table table {
  width: 100%;
  border-collapse: collapse;
}
.table table thead,
.table table tbody,
.table table thead tr,
.table table tbody tr {
  width: 100%;
}
.table table thead th,
.table table tbody th,
.table table tbody td {
  padding: var(--size-05);
}
.table table thead tr {
  background-color: var(--grey-color-3);
  font-size: var(--size-08);
}
.table table tbody tr {
  background-color: var(--white-color);
  font-family: "amiri", sans-serif;
}
.table table tbody tr td {
  font-size: var(--size-09);
}
.table table .text-align-center {
  text-align: center;
}
.table table .text-align-left {
  text-align: left;
}
.table table .text-align-right {
  text-align: right;
}
.table table .text-align-justify {
  text-align: justify;
}
.table table .status {
  background-color: var(--white-color);
  border-radius: var(--size-03);
  font-weight: 700;
  font-size: var(--size-06);
  padding: var(--size-02) var(--size-05);
}
.table table .status.pending {
  border: 1px solid var(--color-3);
  color: var(--color-3);
}
.table table .status.validate {
  border: 1px solid var(--color-1);
  color: var(--color-1);
}
.table table .status.canceled {
  border: 1px solid var(--red-color);
  color: var(--red-color);
}
.table table .status.completed {
  border: 1px solid var(--green-color);
  color: var(--green-color);
}

.pagination {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-05);
}
.pagination .p-left {
  width: 50%;
  display: flex;
  font-family: "amiri", sans-serif;
}
.pagination .p-left span {
  margin-right: var(--size-05);
}
.pagination .p-left select {
  border-radius: var(--size-05);
  padding: var(--size-02);
  margin-right: var(--size-105);
  border: 1px solid var(--grey-color-2);
}
.pagination .p-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.pagination .p-right .button {
  background-color: transparent;
  border-radius: var(--size-05);
  border: none;
  font-size: var(--size-07);
  padding: var(--size-05) var(--size-07);
  margin: 0 var(--size-02);
}
.pagination .p-right .button.btn-inactive {
  background-color: var(--white-color);
  border: 1px solid var(--grey-color-2);
  color: var(--grey-color-1);
}
.pagination .p-right .button.btn-active {
  background-color: var(--black-color);
  border: 1px solid var(--black-color);
  color: var(--white-color);
}

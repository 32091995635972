:root {
  --size-01: 0.1em;
  --size-02: 0.2em;
  --size-03: 0.3em;
  --size-04: 0.4em;
  --size-05: 0.5em;
  --size-06: 0.6em;
  --size-07: 0.7em;
  --size-08: 0.8em;
  --size-09: 0.9em;
  --size-1: 1em;
  --size-101: 1.1em;
  --size-102: 1.2em;
  --size-103: 1.3em;
  --size-104: 1.4em;
  --size-105: 1.5em;
  --size-106: 1.6em;
  --size-107: 1.7em;
  --size-108: 1.8em;
  --size-109: 1.9em;
  --size-2: 2em;
  --size-201: 2.1em;
  --size-202: 2.2em;
  --size-203: 2.3em;
  --size-204: 2.4em;
  --size-205: 2.5em;
  --size-206: 2.6em;
  --size-207: 2.7em;
  --size-208: 2.8em;
  --size-209: 2.9em;
  --size-3: 3em;
  --size-301: 3.1em;
  --size-302: 3.2em;
  --size-303: 3.3em;
  --size-304: 3.4em;
  --size-305: 3.5em;
  --size-306: 3.6em;
  --size-307: 3.7em;
  --size-308: 3.8em;
  --size-309: 3.9em;
  --size-4: 4em;
  --size-401: 4.1em;
  --size-402: 4.2em;
  --size-403: 4.3em;
  --size-404: 4.4em;
  --size-405: 4.5em;
  --size-406: 4.6em;
  --size-407: 4.7em;
  --size-408: 4.8em;
  --size-409: 4.9em;
  --size-5: 5em;
}

.orders {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
}
.orders .inner,
.orders .outer {
  width: 100%;
  height: 100%;
  position: absolute;
}
/* Inner */
.orders .inner {
  display: block;
}
.orders .inner .header-line {
  width: 100%;
  height: 70px;
  display: flex;
}
.orders .inner .header-line .t-1 {
  color: var(--black-color);
  font-size: var(--size-102);
  font-weight: 700;
}
.orders .inner .header-line .t-1 span {
  color: var(--grey-color-1);
  font-size: var(--size-08);
}
.orders .inner .header-line .statistics {
  margin-left: var(--size-5);
  display: flex;
}
.orders .inner .header-line .statistics .stat-item {
  margin-left: var(--size-3);
  border: 1px solid var(--grey-color-2);
  border-radius: var(--size-03);
  padding: var(--size-02) var(--size-05);
  text-align: center;
}
.orders .inner .header-line .statistics .stat-item span {
  font-size: var(--size-08);
}
.orders .inner .header-line .statistics .stat-item .t-2 {
  margin: 0;
  font-size: var(--size-2);
  font-weight: 600;
}
.orders .inner .actions {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-02) 0;
  position: relative;
}
.orders .inner .actions .left {
  width: auto;
  display: flex;
}
.orders .inner .actions .left .inputs-form {
  position: relative;
}
.orders .inner .actions .left .inputs-form input {
  position: relative;
  width: 300px;
  height: 100%;
  padding: var(--size-02) var(--size-02) var(--size-02) var(--size-107);
  border-radius: var(--size-05);
  border: 1px solid var(--grey-color-2);
}
.orders .inner .actions .left .inputs-form .icon {
  position: absolute;
  top: var(--size-05);
  left: var(--size-03);
  z-index: 1;
}
.orders .inner .actions .left .button {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
.orders .inner .actions .left .button .icon {
  margin-right: var(--size-07);
}
.orders .inner .actions .left select {
  border-radius: var(--size-05);
  border-color: var(--grey-color-2);
  font-size: var(--size-08);
  padding: var(--size-02);
}
.orders .inner .actions .middle {
  width: auto;
  display: flex;
}
.orders .inner .actions .middle .inputs-form input {
  position: relative;
  width: auto;
  height: 100%;
  padding: var(--size-02) var(--size-02) var(--size-02) var(--size-107);
  border-radius: var(--size-05);
  border: 1px solid var(--grey-color-2);
}
.orders .inner .actions .middle select {
  border-radius: var(--size-05);
  border-color: var(--grey-color-2);
  font-size: var(--size-08);
  padding: var(--size-02);
}
.orders .inner .actions .middle .button {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
.orders .inner .actions .middle .button .icon {
  margin-right: var(--size-07);
}
.orders .inner .actions .right {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.orders .inner .actions .right .button {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
.orders .inner .actions .right .button:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.orders .inner .actions .right .button .icon {
  margin-right: var(--size-05);
}
.orders .inner .actions .right .button.btn-new {
  background-color: var(--color-1);
}
.orders .inner .actions .right .button.btn-export {
  background-color: var(--black-color);
}
.orders .inner .actions .right .button.btn-canceled {
  background-color: var(--red-color);
}
.orders .inner .actions .right .button.btn-validate {
  background-color: var(--green-color);
}
.orders .inner .content {
  width: 100%;
  height: calc(100% - 110px);
  position: relative;
}
.orders .inner .content .content-inner,
.orders .inner .content .content-outer {
  width: 100%;
  height: 100%;
  position: absolute;
}
.orders .inner .content .content-outer {
  background-color: var(--white-color);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders .inner .content .content-inner .table {
  overflow-y: auto;
}
.orders .inner .content .content-inner .table thead th {
  background-color: var(--grey-color-3);
  position: sticky;
  top: 0; 
  z-index: 1;
}
.orders .inner .content .content-inner .table .btn-action {
  background-color: var(--black-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}
/* Outer */
.orders .outer {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: var(--size-1);
}
.orders .outer .wrapper {
  width: 80%;
  background-color: var(--white-color);
  border-radius: var(--size-05);
  margin: auto;
  padding: var(--size-05);
}
.orders .outer .wrapper .o-head {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.orders .outer .wrapper .o-head .t-2 {
  margin: 0;
  font-size: var(--size-1);
  font-weight: 600;
}
.orders .outer .wrapper .o-head span {
  cursor: pointer;
  font-size: var(--size-2);
}
.orders .outer .wrapper hr {
  border: none;
  width: 100%;
  height: 1px;
  background-color: var(--grey-color-2);
  margin: var(--size-05) 0;
}
.orders .outer .wrapper .o-body {
  width: 100%;
  display: block;
}
.orders .outer .wrapper .o-body .o-up {
  width: 100%;
  display: flex;
  margin-bottom: var(--size-1);
}
.orders .outer .wrapper .o-body .o-up .stock-state {
  width: 50%;
  border: 1px solid var(--grey-color-2);
  border-radius: var(--size-03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.orders .outer .wrapper .o-body .o-up .stock-state .t-3 {
  font-size: var(--size-1);
}
.orders .outer .wrapper .o-body .o-up .stock-state span {
  color: var(--color-1);
  font-size: var(--size-5);
  font-weight: 800;
  font-family: "amiri", sans-serif;
}
.orders .outer .wrapper .o-body .o-up .details {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--size-05);
}
.orders .outer .wrapper .o-body .o-up .details .discount {
  display: flex;
  align-items: center;
}
.orders .outer .wrapper .o-body .o-up .details .discount .discount-btn {
  background-color: var(--grey-color-1);
  border: none;
  border-radius: var(--size-03);
  color: var(--black-color);
  cursor: pointer;
  margin-right: var(--size-1);
  padding: var(--size-05) var(--size-06);
}
/*  */
.orders
  .outer
  .wrapper
  .o-body
  .o-up
  .details
  .discount
  .discount-btn.discount-selected {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: var(--size-03);
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: var(--size-07);
}

.orders
  .outer
  .wrapper
  .o-body
  .o-up
  .details
  .discount
  .discount-btn.discount-selected:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.orders
  .outer
  .wrapper
  .o-body
  .o-up
  .details
  .discount
  .discount-btn.discount-selected:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.orders
  .outer
  .wrapper
  .o-body
  .o-up
  .details
  .discount
  .discount-btn.discount-selected:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
/*  */
.orders .outer .wrapper .o-body .o-up .details .discount .discount-amount {
  font-size: var(--size-105);
}
.orders .outer .wrapper .o-body .o-up .details .button {
  width: 100%;
  background-color: var(--color-1);
}
/*  */
.orders .outer .wrapper .o-body .o-down {
  width: 100%;
  display: block;
  padding: var(--size-1) 0;
}
.orders .outer .wrapper .o-body .o-down .o-down-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-03);
}
.orders .outer .wrapper .o-body .o-down .o-down-head .t-2 {
  color: var(--black-color);
  font-size: var(--size-102);
  font-weight: 700;
}
.orders .outer .wrapper .o-body .o-down .o-down-head .step-btn {
  background-color: var(--black-color);
  border-radius: var(--size-03);
  color: var(--white-color);
  cursor: pointer;
  font-size: var(--size-07);
  padding: var(--size-02) var(--size-05);
}

.orders .outer .wrapper .o-body .o-down .table {
  max-height: 425px;
}
.orders .outer .wrapper .o-body .o-down .table .button {
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: var(--size-07);
  margin: 0 var(--size-02);
}

.orders .outer .wrapper .o-body .o-down .o-down-next {
  width: 100%;
  padding: 0 var(--size-05);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.orders .outer .wrapper .o-body .o-down .o-down-next .input-div {
  width: 49%;
}
.orders .outer .wrapper .o-body .o-down .o-down-next .bills {
  width: 100%;
  border: 1px solid var(--grey-color-2);
  border-radius: var(--size-03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--size-05) 0;
  margin-bottom: var(--size-03);
}
.orders .outer .wrapper .o-body .o-down .o-down-next .bills .t-3 {
  font-size: var(--size-1);
}
.orders .outer .wrapper .o-body .o-down .o-down-next .bills span {
  color: var(--color-1);
  font-size: var(--size-5);
  font-weight: 800;
  font-family: "amiri", sans-serif;
}
.orders .outer .wrapper .o-body .o-down .o-down-next .button {
  width: 100%;
  background-color: var(--color-1);
}
.orders .outer .wrapper .o-body .o-down .o-down-next .discount {
  display: flex;
  align-items: center;
}
.orders .outer .wrapper .o-body .o-down .o-down-next .discount-btn {
  background-color: var(--grey-color-1);
  border: none;
  border-radius: var(--size-03);
  color: var(--black-color);
  cursor: pointer;
  margin-right: var(--size-1);
  padding: var(--size-05) var(--size-06);
}
/*  */
.orders
  .outer
  .wrapper
  .o-body
  .o-down
  .o-down-next
  .discount
  .discount-btn.discount-selected {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: var(--size-03);
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: var(--size-07);
}

.orders
  .outer
  .wrapper
  .o-body
  .o-down
  .o-down-next
  .discount
  .discount-btn.discount-selected:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.orders
  .outer
  .wrapper
  .o-body
  .o-down
  .o-down-next
  .discount
  .discount-btn.discount-selected:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.orders
  .outer
  .wrapper
  .o-body
  .o-down
  .o-down-next
  .discount
  .discount-btn.discount-selected:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
/*  */
.orders
  .outer
  .wrapper
  .o-body
  .o-down
  .o-down-next
  .discount
  .discount-amount {
  font-size: var(--size-105);
}

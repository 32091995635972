/* For desktop: */
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

.col-05 {
  width: 5%;
}
.col-10 {
  width: 10%;
}
.col-15 {
  width: 15%;
}
.col-20 {
  width: 20%;
}
.col-30 {
  width: 30%;
}
.col-35 {
  width: 35%;
}
.col-40 {
  width: 40%;
}
.col-45 {
  width: 45%;
}
.col-55 {
  width: 55%;
}
.col-60 {
  width: 60%;
}
.col-65 {
  width: 65%;
}
.col-70 {
  width: 70%;
}
.col-80 {
  width: 80%;
}
.col-85 {
  width: 85%;
}
.col-90 {
  width: 90%;
}
.col-95 {
  width: 95%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
